<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>
          Agents List
          <v-spacer></v-spacer>
          <v-avatar color="primary">
            <v-icon dark>mdi-face-agent</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="table.headers" :items="allAgents">
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | moment }}
            </template>

            <template v-slot:item.updated_at="{ item }">
              {{ item.updated_at | moment }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-menu offset-x left>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-tooltip left transition="slide-x-reverse-transition">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            block
                            text
                            v-bind="attrs"
                            v-on="on"
                            @click="editData(item)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-row>

                    <v-row>
                      <v-tooltip left transition="slide-x-reverse-transition">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            block
                            text
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteConfirmation(item)"
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="edit.dialog" width="800">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Edit Agent</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="edit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="editAgent" v-if="edit.data">
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="edit.data.name"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Phone"
                  v-model="edit.data.phone"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea label="Message" v-model="edit.message" @input="setLink"></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="primary"
            :loading="edit.loading"
            :disabled="edit.disable"
            @click="saveData"
          >
            <span>save</span>
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="500">
      <v-alert dark class="mb-0" prominent icon="mdi-alert" color="warning">
        <v-row no-gutters class="align-center">
          Yakin Ingin Menghapus Data Ini?
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="deleteData"
            :loading="del.loading"
            :disabled="del.disable"
          >
            <span>ya</span>
          </v-btn>
          <v-btn class="ml-2" depressed @click="del.dialog = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}
      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      table: {
        headers: [
          { text: "Name", value: "name" },
          { text: "Phone", value: "phone" },
          // { text: "Link", value: "link" },
          { text: "Created At", value: "created_at" },
          { text: "Updated At", value: "updated_at" },
          { text: "Action", value: "action" },
        ],
      },
      edit: {
        dialog: false,
        loading: false,
        disable: false,
        message: null,
        data: null,
      },
      del: {
        loading: false,
        disable: false,
        dialog: false,
        id: null,
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
    };
  },
  methods: {
    ...mapActions(["updateAgent", "deleteAgent"]),
    decodeLink(url){
      let text = url.split("&")
      text = text[1].split("=")
      this.edit.message = decodeURIComponent(text[1])
      // console.log(this.edit.message)
    },
    encodeMessage(val){
      return escape(val)
    },
    setLink(){
      let message = this.edit.message
      let text = this.encodeMessage(message)
      let phone = this.edit.data.phone
      let url = "https://api.whatsapp.com/send?phone="+phone+"&text="+text
      this.edit.data.link = url
      // console.log(url)
    },
    editData(value) {
      this.edit.data = value;
      this.decodeLink(value.link)
      this.edit.dialog = true;
    },
    saveData() {
      this.edit.loading = true;
      this.edit.disable = true;

      let data = this.edit.data;
      // console.log(data)
      if (this.updateAgent(data)) {
        this.edit.loading = false;
        this.edit.disable = false;
        this.showNotification("success");
      } else {
        this.edit.loading = false;
        this.edit.disable = false;
        this.showNotification("error");
      }
    },
    deleteConfirmation(value) {
      this.del.dialog = true;
      this.del.id = value.id;
    },
    deleteData() {
      this.del.loading = true;
      this.del.disable = true;

      let id = this.del.id;
      if (this.deleteAgent(id)) {
        this.del.loading = false;
        this.del.disable = false;
        this.del.dialog = false;
        this.showNotification("success");
      } else {
        this.del.loading = false;
        this.del.disable = false;
        this.showNotification("error");
      }
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Data Berhasil Diperbarui";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Data Gagal Diperbarui";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allAgents"]),
  },
  filters: {
    moment: (value) => {
      return moment(value).format("Do MMM YYYY");
    },
  },
};
</script>